<template>
    <section class="text-gray-600">

        <article class="relative">
            <div class="absolute inset-0 bg-gray-300 services-hero--bg">
                <!-- <img src="../assets/HeaderBannerimages/11.png" class="w-full"  /> -->
            </div>
            <!-- <div class="container px-5 py-24 mx-auto">
                <div class="flex flex-wrap w-full mb-20 relative">
                <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
                    <h1 class="sm:text-4xl text-xl  text-left font-bold mb-2 text-white">Services</h1>
                    <div class="h-1 w-20 bg-purple-950 rounded-sm"></div>
                </div>
                <p class="lg:w-1/2 w-full leading-relaxed text-gray-500">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>
                </div>
            </div> -->

            <div class="container px-5 py-10 mx-auto flex">
                <div
                    class="flex flex-col lg:w-2/5 md:ml-auto md:mt-0 md:w-1/2 mt-10 p-8 relative text-left w-full z-10">
                    <h2 class="text-white text-5xl mb-1 font-medium title-font">Services</h2>
                    <p class="leading-relaxed mb-5 text-gray-600">Some great copy goes here.</p>
                </div>
            </div>
        </article>
        <article class="text-gray-600 body-font relative block">
            <div class="container px-5 py-24 mx-auto">
                <div class="flex flex-col text-center w-full mb-12">
                    <h1 class="sm:text-3xl title-font mb-4 tracking-wide leading-snug font-thin text-5xl text-gray-500">
                        Unique Travel Programs That Fit Your Needs.</h1>
                    <p class="lg:w-2/3 mx-auto leading-relaxed text-base">With the ever changing landscape of corporate
                        travel you need to Assure that all your travel needs are met. At Assure Travel we will provide
                        that assurance with the experienced Account Managers guiding you through every step on your
                        corporate travel program. Assure Travel’s mission is to help our clients minimize cost and
                        provide a service level second to none. </p>
                </div>
            </div>
        </article>
        <article>

            <div class="container px-5 pb-24 mx-auto flex flex-wrap flex-col">
                <div class="flex mx-auto lg:mx-0 flex-wrap mb-20">
                    <a v-on:click="activetab='1'" v-bind:class="[ activetab === '1' ? 'active' : '' ]"
                        class="tab-active sm:px-6 py-3 sm:w-1/4 w-auto sm:justify-center justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5 mr-3" viewBox="0 0 24 24">
                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                        </svg>Service 1
                    </a>
                    <a v-on:click="activetab='2'" v-bind:class="[ activetab === '2' ? 'active' : '' ]"
                        class="tab-active sm:px-6 py-3 sm:w-1/4 w-auto sm:justify-center justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5 mr-3" viewBox="0 0 24 24">
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                        </svg>Service 2
                    </a>
                    <a v-on:click="activetab='3'" v-bind:class="[ activetab === '3' ? 'active' : '' ]"
                        class="tab-active  sm:px-6 py-3 sm:w-1/4 w-auto sm:justify-center justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5 mr-3" viewBox="0 0 24 24">
                            <circle cx="12" cy="5" r="3"></circle>
                            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                        </svg>Groups & Meetings
                    </a>
                    <a v-on:click="activetab='4'" v-bind:class="[ activetab === '4' ? 'active' : '' ]"
                        class="tab-active sm:px-6 py-3 sm:w-1/4 w-auto sm:justify-center justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" class="w-5 h-5 mr-3" viewBox="0 0 24 24">
                            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                        </svg>Assure Travel Advantage
                    </a>
                </div>
                <!-- START TAB CONTENT -->
                <div v-if="activetab ==='1'" class="tabcontent">
                    <div class="container px-5 py-24 pt-16 mx-auto flex flex-col">
                        <div class="lg:w-4/6 mx-auto">
                            
                            <div class="flex flex-col sm:flex-row text-left">
                                <div class="sm:w-1/3 sm:pr-8 sm:py-8">
                                    
                                    <div class="flex flex-col text-left justify-start">
                                        <h2 class="font-medium title-font text-gray-900 text-2xl">Service 1
                                        </h2>
                                        <div class="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
                                        <p class="text-base">Raclette knausgaard hella meggs normcore williamsburg
                                            enamel pin sartorial venmo tbh hot chicken gentrify portland.</p>
                                    </div>
                                </div>
                                <div
                                    class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                                    <p class="leading-relaxed text-lg mb-4">Meggings portland fingerstache lyft,
                                        post-ironic fixie man bun banh mi umami everyday carry hexagon locavore direct
                                        trade art party. Locavore small batch listicle gastropub farm-to-table
                                        lumbersexual salvia messenger bag. Coloring book flannel truffaut craft beer
                                        drinking vinegar sartorial, disrupt fashion axe normcore meh butcher. Portland
                                        90's scenester vexillologist forage post-ironic asymmetrical, chartreuse disrupt
                                        butcher paleo intelligentsia pabst before they sold out four loko. 3 wolf moon
                                        brooklyn.</p>
                                    <a class="text-indigo-500 inline-flex items-center">Learn More
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                            stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2"
                                            viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div class="rounded-lg h-64 overflow-hidden">
                                <img alt="content" class="object-cover object-center h-full w-full"
                                    src="https://dummyimage.com/1200x500">
                            </div>
                        </div>
                    </div>

                </div>
                <div v-if="activetab ==='2'" class="tabcontent">
                    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                            <img class="object-cover object-center rounded-sm" alt="hero"
                                src="https://dummyimage.com/720x600">
                        </div>
                        <div
                            class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Service 2
                                <br class="hidden lg:inline-block">
                            </h1>
                            <p class="mb-8 leading-relaxed">Copper mug try-hard pitchfork pour-over freegan heirloom
                                neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote
                                bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>
                            <div class="flex justify-center">
                                <button
                                    class="inline-flex text-white bg-yellow-950 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-900 rounded-sm text-lg">Talk
                                    with consultant</button>

                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="activetab ==='3'" class="tabcontent">
                    <div class="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
                        <div
                            class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Groups & Meetings
                                <br class="hidden lg:inline-block">
                            </h1>
                            <p class="mb-8 leading-relaxed">Assure Travel provides extraordinarily crafted programs for 10 to 10,000 - serving corporations, organizations (profit and non-profit), Universities, Alumni Associations and more.</p>
                            <div class="flex justify-center">
                                <button
                                    class="inline-flex text-white bg-yellow-950 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-900 rounded-sm text-lg">Talk
                                    with consultant</button>
                            </div>
                        </div>
                        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6  ml-auto">
                            <img class="object-cover object-center rounded-sm" alt="hero"
                                src="../assets/services/groupsmeetings1.png">
                        </div>
                    </div>
                    <div class="container flex flex-wrap px-5 py-24 mx-auto items-center text-left">
                        <div class="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-200">
                        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">What makes a successful meeting?</h1>
                        <p class="leading-relaxed text-base">Organization, experience, attention to detail - and creativity. How do Assure Travel’s clients meet with success? By relying on Assure Travel's years of experience - in all of the above! From the sublime to the extraordinary, from the simple to the elaborate - we have planned it. On budget. Every time.</p>
                        </div>
                        <div class="flex flex-col md:w-1/2 md:pl-12">
                        <h2 class="title-font font-semibold text-gray-800 tracking-wider text-sm mb-3">How do we do it?</h2>
                        <nav class="flex flex-wrap list-none -mb-1">
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Function space assignment</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Appropriate room setup</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Audiovisual needs</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Phone lines</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Office equipment</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Theme Parties</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Security arangements</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Signage ordering and placement</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Floor plan design</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Guest amenities</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Floral arrangements</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Photographers • Translators</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Registration Personnel</p>
                            </li>
                            <li class="mb-1 w-1/2">
                            <p class="text-gray-600 hover:text-gray-800">Audio  Taping</p>
                            </li>
                        </nav>
                        </div>
                    </div>

                </div>
                <div v-if="activetab ==='4'" class="tabcontent">
                    <div class="container pb-24  pt-5 mx-auto text-left">
                        <div class="text-center mb-20">
                            <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
                                Assure Travel Advantage</h1>
                            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">Great salesy blurb can go
                                here about items this service offers.</p>
                        </div>
                        <div class="flex flex-wrap w-full sm:mx-auto sm:mb-2 -mx-2">
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Development of customized travel management goals and objectives</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Assistance with the creation or revision of an effective travel policy</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Hassle-free full-service travel management, with online or on-site account implementation</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Dedicated account management from highly experienced agents</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Quality control measures to ensure corporate travel policy compliance</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Benchmark reporting and analysis to identify cost saving opportunities</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Advanced online booking tools</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Customized, real-time travel information reporting</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Comprehensive Duty of Care and Emergency Management</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">24/7 global traveler emergency assistance</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Exclusive corporate hotel rate program</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Hotel block space allotments with guaranteed last room availability</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Unused non-refundable ticket management</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Pre-trip audits on all itineraries to optimize low fare opportunities</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Agency-negotiated car rental rates & airport parking discounts</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Executive VIP & Concierge Services</span>
                                </div>
                            </div>
                            <div class="p-2 sm:w-1/2 lg:w-1/3 w-full">
                                <div class="bg-gray-100 rounded-sm flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                        stroke-linejoin="round" stroke-width="3"
                                        class="text-purple-950 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span class="title-font font-medium">Full service Meetings & Incentives division</span>
                                </div>
                            </div>
                        </div>
                        <button
                            class="flex mx-auto mt-16 text-white bg-purple-950 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded-sm text-lg">Speak
                            with travel Consultant</button>
                    </div>

                </div>
            </div>

        </article>

    </section>

</template>
<script>
    export default {
        name: "Services",
        components: {
            // HelloWorld,
        },
        data() {
            return {
                activetab: '1',
            };
        },
        methods: {
            setTabActive(tab) {
                this.activeTab = tab;
            }
        },
        computed: {
            tabContent() {
                return this.tabs[this.activeTab];
            },
        },
    };
</script>
<style scoped>
    .services-hero--bg {
        background-image: url('../assets/HeaderBannerimages/11.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .tab-active.active {
        @apply border-b-2 bg-gray-100 border-purple-950 text-purple-950 tracking-wider rounded-t-sm
    }
</style>
<template>
  <div id="nav" class="w-full border-b-default border-gray-300">
    <nav class="container mx-auto">
      <header class="py-4 px-4 flex justify-between items-center select-none">
        <div class="text-2xl font-semibold flex justify-between items-center">
          <a href="/">
            <img class="h-16" alt="Assure Travel logo" src="../assets/logo-copy.jpg" />
          </a>
        </div>

        <div class="text-gray-800 sm:block md:hidden">
          <div @click="isOpen =! isOpen" v-show="!isOpen">
            <svg class="fill-current w-8 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </div>

          <div @click="isOpen =! isOpen" v-show="isOpen">
            <svg class="fill-current w-8 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </div>
        </div>

        <!-- Desktop Links -->
        <div class="hidden md:block text-sm">
          <router-link to="/corporatetravel" class="py-2 px-3 ml-2 hover:bg-purple-950 hover:text-white rounded-sm">
            Corporate Travel
          </router-link>

          <router-link to="/about" class="py-2 px-3 ml-2 hover:bg-purple-950 hover:text-white rounded-sm">
            About Us
          </router-link>

          <router-link to="/resources" class="py-2 px-3 ml-2 hover:bg-purple-950 hover:text-white rounded-sm">
            Resources
          </router-link>

          <router-link to="/contact"
            class="py-2 px-3 ml-2 hover:bg-yellow-950 bg-purple-950 rounded-sm shadow-lg border text-white">
            Contact Us
          </router-link>
        </div>
      </header>

      <!-- Mobile Links -->
      <transition name="fade">
        <div class="bg-white px-4 py-4 select-none border-b md:hidden" v-if="isOpen">
          <div v-if="isOpen" v-on:click="isOpen = !isOpen">
          <router-link to="/corporatetravel"
            class="block mb-2 font-semibold text-gray-800 py-2 px-3 hover:bg-gray-200 rounded-sm cursor-pointer">
            Corporate Travel
          </router-link>
          </div>
          <div v-if="isOpen" v-on:click="isOpen = !isOpen">
          <router-link to="/about"
            class="block mb-2 font-semibold text-gray-800 py-2 px-3 hover:bg-gray-200 rounded-sm cursor-pointer">
            About Us
          </router-link>
          </div>
          <div v-if="isOpen" v-on:click="isOpen = !isOpen">
          <router-link to="/resources"
            class="block mb-2 font-semibold text-gray-800 py-2 px-3 hover:bg-gray-200 rounded-sm cursor-pointer">
            Resources
          </router-link>
          </div>
          <div v-if="isOpen" v-on:click="isOpen = !isOpen">
          <router-link to="/contact"
            class="block font-semibold text-gray-800 py-2 px-3 hover:bg-gray-200 rounded-sm cursor-pointer">
            Contact Us
          </router-link>
          </div>
        </div>
      </transition>

    </nav>
  </div>
</template>

<script>
  export default {
    name: "HeaderNav",
    data() {
      return {
        openMenu: false,
        isMenu: false,
        isOpen: false,
      };
    }
  };
</script>
<style scoped>
  nav a.router-link-exact-active {
    @apply border-b-2 border-purple-950;
  }

  nav a.router-link-exact-active:hover {
    @apply bg-transparent text-gray-800 cursor-default;
  }

  .fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: 0.2s opacity ease-out;
}
</style>
<template>
  <div class="hero-section-3 py-40">
    <div
      class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center"
    >
      <section class="container mx-auto text-center py-6 mb-12">
        <h1
          class="w-full my-2 text-5xl font-bold leading-tight text-center text-yellow-600"
        >
          Meet Assure Travel
        </h1>
        <div class="w-full mb-4">
          <div
            class="h-1 mx-auto bg-purple-900 w-1/6 my-0 py-0 rounded-t"
          ></div>
        </div>
        <h3 class="my-4 text-3xl leading-tight text-purple-900">
          This is an
          <span class="font-bold text-yellow-600">out of the box</span> thinking
          travel management company
        </h3>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroSection",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero-section-1 {
  background-image: url("../assets/HeaderBannerimages/3.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-section-2 {
  background-image: url("../assets/HeaderBannerimages/3.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-section-3 {
  background-image: url("../assets/hero-3.png");
  background-repeat: no-repeat;
  background-size: cover;
}
/* .hero-section{
background-image: url('https://res.cloudinary.com/options-travel/image/upload/v1617201988/600f194a40a6a982fc2a3d14_720-bg-vid-poster-00001.jpg');
background-repeat: no-repeat;
background-size: cover;} */
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<template>
  <footer class="body-font lg:mt-24 text-gray-900 relative">
    <div class="custom-shape-divider-top-footer bg-white">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
          class="shape-fill"></path>
      </svg>
    </div>
    <div class="container flex flex-col flex-wrap md:flex-nowrap md:flex-row md:items-center mx-auto px-5 py-24">
      <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
        <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          <img src="../assets/logo-new.png" class="w-40" />
          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
        </svg> -->
          <!-- <span class="ml-3 text-xl">AssureTravel</span> -->
        </a>
        <p class="mt-2 text-sm text-gray-500">
          An out of the box thinking travel management company.
        </p>
      </div>
      <div class="flex flex-wrap h-full lg:mt-0 md:pl-20 md:text-left mt-10 text-center w-full">
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none">
            <li>
              <router-link to="/" class="text-gray-800 hover:text-gray-800">Home</router-link>
            </li>
            <li>
              <router-link to="/about" class="text-gray-800 hover:text-gray-800">About Us</router-link>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/3 md:w-1/2 w-full px-4">
          <nav class="list-none">
            <li>
              <router-link to="/corporatetravel" class="text-gray-800 hover:text-gray-800">Corporate Travel
              </router-link>
            </li>
            <li>
              <a href="https://20191118-485.tempotrip.com/" target="_blank"
                class="text-gray-800 hover:text-gray-800">Leisure Travel</a>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/3 md:w-1/2 w-full px-4">
          <nav class="list-none">
            <li>
              <router-link to="/resources" class="text-gray-800 hover:text-gray-800">Resources</router-link>
            </li>
            <li>
              <router-link to="/contact" class="text-gray-800 hover:text-gray-800">Contact Us</router-link>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/3 md:w-1/2 w-full px-4">
        </div>
      </div>
    </div>
    <div class="bg-gray-100">
      <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p class="text-gray-700 text-sm text-center sm:text-left">
          © Assure Travel —
          <a href="" rel="noopener noreferrer" class="text-gray-700 ml-1" target="_blank">@assuretravel</a>
        </p>
        <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
          <a class="text-gray-500">
            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5"
              viewBox="0 0 24 24">
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>
          <a class="ml-3 text-gray-500">
            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5"
              viewBox="0 0 24 24">
              <path
                d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z">
              </path>
            </svg>
          </a>
          <a class="ml-3 text-gray-500">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              class="w-5 h-5" viewBox="0 0 24 24">
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </a>
          <a class="ml-3 text-gray-500">
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
              stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
              <path stroke="none"
                d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
              <circle cx="4" cy="4" r="2" stroke="none"></circle>
            </svg>
          </a>
        </span>
      </div>
    </div>

  </footer>
</template>
<script>
  export default {
    name: "FooterSection",
    props: {},
  };
</script>
<style scoped>
  li a {
    cursor: pointer;
  }

  .custom-shape-divider-top-footer {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-top-footer svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
  }

  .custom-shape-divider-top-footer .shape-fill {
    fill: #1D156B;
  }
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Corporatetravel from "../views/CorporateTravel.vue";
// import Resources from  "../views/Resources.vue";
// import Contact from "../views/Contact.vue";
import Services from "../views/Services.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/corporatetravel",
    name: "corporateTravel",
    // route level code-splitting
    // this generates a separate chunk (corporatetravel.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "CorporateTravel" */ "../views/CorporateTravel.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/resources",
    name: "Resources",
    // route level code-splitting
    // this generates a separate chunk (resources.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "resources" */ "../views/Resources.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 ,
    behavior: 'smooth',
  }}
},
  routes,
  
});

export default router;

/* eslint-disable */
<template>
  <section class="Home-Page">
    <HeroSection />
    <article class="text-gray-800 text-left">
      <div class="container lg:py-24 mx-auto px-5 py-24">
        <div class="flex flex-wrap lg:mb-20 w-full">
          <div class="lg:w-1/2 w-full mb-6 lg:mb-0 px-3">
            <h1 class="sm:text-5xl text-4xl font-medium title-font mb-2 text-purple-950 uppercase">
              What makes our agency Different
            </h1>
            <div class="h-1 lg:w-9/12 bg-purple-950 rounded"></div>
            <router-link to="/contact"
              class="text-purple-900 lg:inline-flex items-center text-xl mt-5 font-bold hidden">Let's Discuss Your Needs
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </router-link>

          </div>
          <p
            class="lg:w-1/2 w-full tracking-wide leading-snug font-thin px-3 lg:px-0 text-3xl lg:text-5xl text-gray-700">
            We provide the benefits of a traditional travel management company but
            not the cost of one. We design unique travel programs that fit your
            companies needs.
          </p>
          <router-link to="/contact"
            class="text-purple-950 inline-flex items-center text-xl mt-5 font-bold lg:hidden px-3">Let's Discuss Your
            Needs
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              class="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </router-link>
        </div>
      </div>
    </article>
    <article class="bg-purple-950 content-center flex flex-col justify-start lg:py-32 pb-64 pt-24 relative">
      <div class="custom-shape-divider-top">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            class="shape-fill"></path>
        </svg>
      </div>
      <div class="container px-5 py-24 mx-auto">
        <div class="-mb-10 -mt-4 -mx-4 flex flex-wrap md:space-y-0 relative sm:-m-4 space-y-20 text-left z-50">
          <div class="p-4 md:w-1/3 flex">
            <div class="flex-grow flex flex-col pl-6">
              <div class="overflow-hidden rounded-lg w-full">
                <img alt="content" class="h-32 lg:h-48 mb-5 object-center object-cover w-auto"
                  src="../assets/services/s1.png">
              </div>
              <h2 class="text-white text-2xl lg:text-3xl title-font font-medium mb-2">Corporate Travel Management</h2>
              <p class="leading-relaxed text-white text-lg mb-5">Review, analyze and design a travel program that fits
                your
                company.
              </p>
              <p class="leading-relaxed text-white text-lg">Whether your employees are on the road every week, every month, or
                once in a blue moon, booking their business travel can be time consuming and costly if it’s not managed
                properly.
              </p>
            </div>
          </div>
          <div class="p-4 md:w-1/3 flex">
            <div class="flex-grow flex flex-col pl-6">
              <div class="overflow-hidden rounded-lg w-full">
                <img alt="content" class="h-32 lg:h-48 mb-5 object-center object-cover w-auto"
                  src="../assets/services/s2.png">
              </div>
              <h2 class="text-white text-2xl lg:text-3xl title-font font-medium mb-2">Groups and Meetings Travel</h2>
              <p class="leading-relaxed text-white text-lg mb-5">Provide platform to facilitate travel for all size
                groups and meetings.</p>
              <p class="leading-relaxed text-white text-lg">Assure Travel provides extraordinarily crafted programs for
                10 to 10,000 - serving corporations, organizations (profit and non-profit), Universities, Alumni
                Associations and more.</p>
            </div>
          </div>
          <div class="p-4 md:w-1/3 flex">
            <div class="flex-grow flex flex-col pl-6">
              <div class="overflow-hidden rounded-lg w-full">
                <img alt="content" class="h-32 lg:h-48 mb-5 object-center object-cover w-auto"
                  src="../assets/services/s3.png">
              </div>
              <h2 class="text-white text-2xl lg:text-3xl title-font font-medium mb-2">Travel & Expense Tool Consulting
              </h2>
              <p class="text-left leading-relaxed text-white text-lg mb-5">Assist with implementation, design and
                optimizing
                your online travel & expense tools.</p>
              <p class="text-left leading-relaxed text-white text-lg">Travel and expense management programs that
                streamline booking protocols, manage and reduce costs, and generate company-wide agreement and
                compliance.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-shape-divider-bottom">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"></path>
        </svg>
      </div>
    </article>
    <article class="text-gray-800 body-font">
      <div class="container px-5 py-32 mx-auto">
        <div class="flex flex-wrap w-full mb-20">
          <div class="w-full mb-6 lg:mb-0 px-3">
            <h1 class="text-left sm:text-5xl text-4xl font-bold title-font mb-2 text-purple-950 uppercase">
              Technology Driven
            </h1>
            <p class="text-left w-full tracking-wide leading-snug font-thin text-3xl lg:text-5xl text-gray-700">
              We develop and use the best technology in the industry to create a seamless & efficient travel experience
              for the end-users.
            </p>
            <div class="h-px mt-10 lg:w-10/12 bg-purple-950 rounded"></div>
          </div>

        </div>
        <div class="container px-5 py-4 mx-auto flex flex-wrap lg:items-center">
          <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
            <img alt="feature" class="object-contain object-center h-full w-full"
              src="../assets/services/tech-driven-solution.png">
          </div>
          <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
            <div class="flex flex-col mb-10 lg:items-start items-center">
              <div class="flex-grow">
                <h2 class="text-purple-950 text-4xl title-font font-medium mb-3">Online Booking Tools</h2>
                <p class="leading-relaxed text-2xl">The best online booking tools to suit your business.</p>
              </div>
            </div>
            <div class="flex flex-col mb-10 lg:items-start items-center">
              <div class="flex-grow">
                <h2 class="text-purple-950 text-4xl title-font font-medium mb-3">Reporting</h2>
                <p class="leading-relaxed text-2xl">Data that transforms your travel program.</p>
              </div>
            </div>
            <div class="flex flex-col mb-10 lg:items-start items-center">
              <div class="flex-grow">
                <h2 class="text-purple-950 text-4xl title-font font-medium mb-3">Payments & Expenses</h2>
                <p class="leading-relaxed text-2xl">A streamlined expense management process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
    <article class="text-gray-800 body-font bg-gray-100 relative">
      <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
          <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-purple-950">
            Trusted by Many!
          </h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
            Our goal is to build solid relationships with our clients locally
            and nationally by providing competitive pricing, quality service and
            innovative technology; while maintaining the highest standard of
            excellence
          </p>
        </div>
        <div class="flex flex-wrap -m-4 text-center">
          <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <svg aria-hidden="true" class="h-12 inline-block mb-3 text-indigo-700 w-12" style="-ms-transform:rotate(360deg);-webkit-transform:rotate(360deg)" transform="rotate(360)" viewBox="0 0 22 22" fill="none">
                <defs></defs>
                <path fill="currentColor" d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7V19h-8v2h8c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62z"></path>
                <circle cx="9" cy="13" r="1" fill="currentColor"></circle>
                <circle cx="15" cy="13" r="1" fill="currentColor"></circle>
                <path fill="currentColor" d="M18 11.03A6.04 6.04 0 0012.05 6c-3.03 0-6.29 2.51-6.03 6.45a8.075 8.075 0 004.86-5.89c1.31 2.63 4 4.44 7.12 4.47z"></path>
              </svg>
              <!-- <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                class="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                <path d="M8 17l4 4 4-4m-4-5v9"></path>
                <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
              </svg> -->
              <h2 class="title-font font-medium text-3xl text-gray-900">
                30+
              </h2>
              <p class="leading-relaxed">Avg. Agent Years of Experience</p>
            </div>
          </div>
          <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                class="text-indigo-700 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
              </svg>
              <h2 class="title-font font-medium text-3xl text-gray-900">100+</h2>
              <p class="leading-relaxed">Clients</p>
            </div>
          </div>
          <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" class="text-indigo-700 w-12 h-12 mb-3 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
              <!-- <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                class="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
              </svg> -->
              <h2 class="title-font font-medium text-3xl text-gray-900">
                22.5k
              </h2>
              <p class="leading-relaxed">Travelers</p>
            </div>
          </div>
          <div class="p-4 md:w-1/4 sm:w-1/2 w-full mb-10">
            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                class="text-indigo-700 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
              </svg>
              <h2 class="title-font font-medium text-3xl text-gray-900">97%</h2>
              <p class="leading-relaxed">Retention Rate</p>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:-mb-24 custom-shape-divider-bottom-2 bg-gray-100"><svg data-v-039c5b43="" data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path data-v-039c5b43=""
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"></path>
        </svg></div>
    </article>
  </section>
</template>

<script>
  // @ is an alias to /src
  // import HelloWorld from "@/components/HelloWorld.vue";
  import HeroSection from "@/components/HeroSection.vue";
  export default {
    name: "Home",
    components: {
      HeroSection,
    },
  };
</script>
<style scoped>
  .bg-divider {
    background-image: url('../assets/section-divider.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
  }

  .custom-shape-divider-bottom {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom svg {
    position: relative;
    display: block;
    width: calc(144% + 1.3px);
    height: 138px;
  }

  .custom-shape-divider-bottom .shape-fill {
    fill: #FFFFFF;
  }

  .custom-shape-divider-top {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-top svg {
    position: relative;
    display: block;
    width: calc(127% + 1.3px);
    height: 58px;
    transform: rotateY(180deg);
  }

  .custom-shape-divider-top .shape-fill {
    fill: #FFFFFF;
  }

  .custom-shape-divider-bottom-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-2 svg {
    position: relative;
    display: block;
    width: calc(146% + 1.3px);
    height: 98px;
    transform: rotateY(180deg);
  }

  .custom-shape-divider-bottom-2 .shape-fill {
    fill: #1D156B;
  }
</style>